<template lang="pug">
.error__container
  img(src="~assets/images/svg/error404.svg").error__img
  .error__text
    h1.error__title Ошибка 404!
    h3.error__subtitle Страница не найдена
</template>

<script>
import { mapActions } from 'pinia'
import { useSettingsStore } from '~/stores/settings'

export default {
  async created() {
    await this.getLangTree()
  },
  methods: {
    ...mapActions(useSettingsStore, ['getLangTree']),
  },
}
</script>

<script setup>
useHead({
  title: 'Ошибка',
})
</script>

<style lang="scss" scoped>
.row {
  margin: 0;
}

.error {

  &__container {
    width: 100%;
    padding: 20px;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @include l-desktop {
      height: 100vh;
      padding: 0;
      flex-direction: row;
      justify-content: center;
    }
  }

  &__img {
    margin-bottom: 20px;
    width: 290px;
    height: 270px;

    @include l-desktop {
      margin-bottom: 0;
      width: auto;
      height: auto;
    }
  }

  &__title {
    font-size: 34px;
    margin-bottom: 5px;
    color: $gray;
  }

  &__subtitle {
    color: $gray;
  }
}
</style>